import ContentView from '../views/content-view.vue';
import NotFound from '../views/not-found-view.vue';
import { menuItemType } from '../types/menu-types';
import { wpPageType } from '../types/wordpress-types';
import api from '../functions/global/api-functions';

export async function getMenus() {
  const wpPages = await api('pages');
  const wpPagesData: wpPageType[] = wpPages.data;
  const headerMenu: menuItemType[] = [];

  function addPage(menu: menuItemType[], wpPage: wpPageType) {
    menu.push({
      path: `/${wpPage.slug === 'home' ? '' : wpPage.slug}`,
      name: wpPage.title.rendered,
      component: ContentView,
      props: {
        title: wpPage.title.rendered,
        content: wpPage.content.rendered
      }
    });
  }

  // build menus
  for (const wpPage of wpPagesData) {
    addPage(headerMenu, wpPage);
  }

  const abstract = [
    {
      path: '/:pathMatch(.*)*',
      name: 'Fehler',
      component: NotFound
    }
  ];

  return {
    header: headerMenu,
    abstract: abstract
  };
}

<script lang="ts">
export default {
  name: 'sectionComponent',
  props: {
    id: {
      type: String,
      default: ''
    },
    class: {
      type: String,
      default: ''
    },
    container: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<template>
  <section :id="$props.id" :class="$props.class">
    <div class="container" v-if="container">
      <slot></slot>
    </div>
    <slot v-else></slot>
  </section>
</template>

<style lang="scss" scoped>
section {
  padding: 1rem 0;
}
</style>

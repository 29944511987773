import axios from 'axios';

export default async function api(type: string) {
  const api = axios.create({
    baseURL: 'https://asf-lk-osnabrueck.de/wp-json/wp/v2/',
    timeout: 10_000
  });
  const apiData = await api.get(type);

  return apiData;
}

import { createApp } from 'vue';
import App from './app.vue';
import { getRouter } from './router';
import './styles/_import.scss';

const app = createApp(App);

// eslint-disable-next-line unicorn/prefer-top-level-await
getRouter().then((router) => {
  app.use(router);

  app.mount('#app');
});

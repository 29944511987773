<script lang="ts">
import { RouterView } from 'vue-router';

export default {
  name: 'main-component',
  components: {
    RouterView
  }
};
</script>

<template>
  <main>
    <RouterView />
  </main>
</template>

<script lang="ts">
import { RouterLink } from 'vue-router';

export default {
  name: 'menuComponent',
  props: {
    menu: {
      type: Object
    },
    menuClasses: {
      type: Array
    }
  },
  components: {
    RouterLink
  }
};
</script>

<template>
  <ul :class="menuClasses">
    <li
      v-for="item in menu"
      :key="item.path"
      :class="item.submenu ? 'has-submenu' : ''"
    >
      <RouterLink :to="item.path" v-if="item.component">
        <span>{{ item.name }}</span>
      </RouterLink>
      <a v-else>
        <span>{{ item.name }}</span>
      </a>
      <menuComponent
        v-if="item.submenu && item.submenu.length > 0"
        :menu="item.submenu"
        :menuClasses="['submenu']"
      />
    </li>
  </ul>
</template>

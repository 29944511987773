<script lang="ts">
import { RouterLink } from 'vue-router';
import sectionComponent from '../components/global/section-component.vue';

export default {
  name: 'not-found-view',
  components: {
    RouterLink,
    sectionComponent
  }
};
</script>

<template>
  <sectionComponent>
    <p>Diese Seite konnte nicht gefunden werden.</p>
    <RouterLink to="/">Zurück zur Startseite</RouterLink>
  </sectionComponent>
</template>

<script lang="ts">
import { getMenus } from '../../router/menu';
import { menuItemType } from '../../types/menu-types';
import menuComponent from './menu-component.vue';
import sectionComponent from './section-component.vue';

let menus: {
  header: menuItemType[];
  footer: menuItemType[];
  abstract: menuItemType[];
};

// eslint-disable-next-line unicorn/prefer-top-level-await
getMenus().then((menusFromFunction) => {
  menus = menusFromFunction;
});

export default {
  name: 'header-component',
  components: {
    menuComponent,
    sectionComponent
  },
  data() {
    return {
      menus
    };
  }
};
</script>

<template>
  <header v-if="menus">
    <sectionComponent>
      <nav class="navigation">
        <menuComponent class="menu" :menu="[...menus.header]" />
      </nav>
    </sectionComponent>
  </header>
</template>

<style lang="scss">
@use 'sass:map';
@import '@/styles/colors';
@import '@/styles/mixins';

.navigation {
  .menu {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: block;
      line-height: 1;
      padding: 0 1rem;
      border-right: 0.125rem solid map.get($colors, 'white');

      &:last-child {
        border-right: none;
      }

      a {
        display: block;
        padding: 0.25rem 0;
        text-transform: uppercase;

        &.router-link-active {
          color: map.get($colors, 'primary');
        }
      }
    }

    @include media-breakpoint-down(lg) {
      justify-content: flex-start;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;

      li {
        padding: 0;
        margin-bottom: 1rem;
        border-right: none;
        border-bottom: 0.125rem solid map.get($colors, 'white');

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>

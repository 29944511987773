<script lang="ts">
import sectionComponent from '../components/global/section-component.vue';

export default {
  name: 'content-view',
  components: {
    sectionComponent
  },
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  }
};
</script>

<template>
  <sectionComponent :class="$props.title">
    <h1 class="title" v-html="$props.title"></h1>
    <div class="content" v-html="$props.content"></div>
  </sectionComponent>
</template>

<style lang="scss">
@use 'sass:map';
@import '@/styles/variables';
@import '@/styles/colors';
@import '@/styles/mixins';

.title {
  display: flex;
  align-items: center;
  position: relative;
  color: map.get($colors, 'white');

  &::before {
    content: '';
    width: 3rem;
    height: 0.125rem;
    margin-right: 0.5rem;
    background-color: map.get($colors, 'white');
  }
}

.content {
  .wp-block-heading {
    span {
      strong {
        font-size: map.get($heading-font-sizes, 'h2');
        color: map.get($colors, 'secondary');
      }
    }
  }

  .wp-block-group {
    > * {
      display: flex;

      p {
        display: flex;
        margin: 0 1rem 0 0;

        &:last-child {
          margin-right: 0;
        }
      }

      a {
        display: inline-flex;
        align-items: center;

        &:hover::before {
          background-color: map.get($colors, 'primary');
        }

        &::before {
          content: '';
          display: block;
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.5rem;
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
          background-color: map.get($colors, 'white');
          transition: $transition-base;
        }

        &.phone::before {
          mask-image: url('@/assets/icons/phone.svg');
        }

        &.mail::before {
          mask-image: url('@/assets/icons/mail.svg');
        }
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;

        p {
          margin: 0 0 1rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>

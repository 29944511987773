import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { getMenus } from './menu';
import { menuItemType } from '../types/menu-types';

function createRoutes(routes: menuItemType[]) {
  const result: menuItemType[] = [];

  function handleRoutes(routes: menuItemType[]) {
    for (const route of routes) {
      if (route.submenu) {
        handleRoutes(route.submenu);
      }

      if (route.component) {
        result.push({
          path: route.path,
          name: route.name,
          component: route.component,
          props: route.props
        });
      }
    }
  }

  handleRoutes(routes);

  return result as RouteRecordRaw[];
}

export async function getRouter() {
  const menus = await getMenus();
  const routes = [...menus.header, ...menus.abstract];

  const router = createRouter({
    history: createWebHistory('/'),
    routes: createRoutes(routes)
  });

  router.beforeEach((to, _from, next) => {
    document.title = to.name ? (to.name as string) : 'Fehler';
    next();
  });

  return router;
}

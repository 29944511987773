<script lang="ts">
import vueHeader from './components/global/header-component.vue';
import vueMain from './components/global/main-component.vue';

export default {
  name: 'app',
  components: {
    vueHeader,
    vueMain
  }
};
</script>

<template>
  <vueHeader />

  <vueMain />
</template>
